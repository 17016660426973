import React, { useState, useEffect } from 'react';
import './App.css';
import { RouteComponentProps } from 'react-router';
import axios from 'axios';
import check from './icons/check.svg';
import cross from './icons/cross.svg';
import qrcode from './icons/qrcode.svg';
import QRCode from 'qrcode.react';
import config from './config';

type TParams = { shortPath: string };

interface ObjectData {
  id: string;
  createdAt: string;
  name: string;
  shortPath: string;
  creatorEmail: string;
  versions: VersionData[];
}

interface VersionData {
  id: string;
  currentIndex: number;
  lastestIndex: number;
  createdAt: string;
  name: string;
  shortPath: string;
}

interface transferObjectData {
  data: ObjectData;
}

interface transferVersionsData {
  versions: VersionData[];
}

interface transferVersionData {
  version: VersionData;
  lastestIndex: number;
}

interface transferCreateVersionData {
  data: ObjectData;
  GetObject: () => Promise<void>;
}

const File: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState<ObjectData | undefined>(undefined);

  useEffect(() => {
    GetObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function GetObject() {
    setLoading(true);
    const path = `objects/${match.params.shortPath}`;
    try {
      const response = await axios.get(path);
      const data: ObjectData = response.data;
      setFile(data);
      setLoading(false);
    } catch (err) {
      setError(true);
    }
  }

  if (loading) return <React.Fragment>loading...</React.Fragment>;
  if (error) return <Error />;
  if (!file) return null;
  const link = `${config.WebURL}/e/${file.shortPath}`;
  return (
    <React.Fragment>
      <FileDetail data={file} />
      <Versions versions={file.versions} />
      <CreateVersion GetObject={GetObject} data={file} />
      <p style={{ fontSize: '0.6em' }}>
        Share this link to edit:{' '}
        <a className='link-button' href={link}>
          {link}
        </a>
      </p>
    </React.Fragment>
  );
};

const Error: React.FC = () => {
  return <span className='invalid-link'>This link is not valid anymore.</span>;
};

const FileDetail: React.FC<transferObjectData> = ({ data }) => {
  return (
    <div className='file-detail'>
      <p>
        <span>File name:</span> {data.name}
      </p>
      <p>
        <span>Creator:</span> {data.creatorEmail}
      </p>
      <p>
        <span>Created at:</span> {new Date(data.createdAt).toLocaleString()}
      </p>
    </div>
  );
};

const Versions: React.FC<transferVersionsData> = ({ versions }) => {
  if (versions.length === 0)
    return (
      <div>
        <p className='revisions-title'>-- revisions --</p>
        <p className='revisions-empty'>Empty revisions</p>
      </div>
    );

  const lastestIndex = Math.max.apply(Math, versions.map(v => v.currentIndex));

  return (
    <div>
      <p className='revisions-title'>-- revisions --</p>
      <p className='version-list'>
        <span>status</span>
        <span>iteration</span>
        <span>name</span>
        <span>created at</span>
        <span>QR code</span>
      </p>
      {versions
        .sort((a, b) => a.currentIndex - b.currentIndex)
        .map(version => (
          <Version key={version.id} version={version} lastestIndex={lastestIndex} />
        ))}
    </div>
  );
};

const Version: React.FC<transferVersionData> = ({ version, lastestIndex }) => {
  const [showQR, setShowQR] = useState<boolean>(false);
  const link = `${config.WebURL}/${version.shortPath}`;
  return (
    <React.Fragment>
      <p className='version-list'>
        <span>
          {version.currentIndex === lastestIndex ? <img alt='check' src={check} width='25px' /> : <img alt='cross' src={cross} width='25px' />}
        </span>
        <span>{version.currentIndex}</span>
        <span>{version.name}</span>
        <span>{new Date(version.createdAt).toLocaleString()}</span>
        <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => setShowQR(!showQR)}>
          <img alt='qrcode' src={qrcode} width='30px' />
        </span>
      </p>
      {showQR && (
        <div style={{ padding: '5px', background: 'white' }}>
          <QRCode value={link} size={200} />
          <p>
            <a className='link-button' href={link}>
              {link}
            </a>
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

const CreateVersion: React.FC<transferCreateVersionData> = ({ GetObject, data }) => {
  const [name, setName] = useState<string>('');

  async function handelCreate() {
    const path = `versions`;
    const objectShortPath = data.shortPath;
    const postData = { objectShortPath, name };
    try {
      await axios.post(path, postData);
      await GetObject();
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div>
      <input value={name} onChange={e => setName(e.target.value)} placeholder='Create a revision...'></input>
      <span className='link-button' style={{ marginLeft: '10px' }} onClick={handelCreate}>
        +
      </span>
    </div>
  );
};

export default File;
