import React, { useState, Dispatch } from 'react';
import { BrowserRouter as Router, Route, Switch, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

import './App.css';
import Version from './Version';
import Scan from './Scan';
import File from './File';

interface MainBoxProps {
  setCreate: Dispatch<React.SetStateAction<boolean>>;
  setScan: Dispatch<React.SetStateAction<boolean>>;
}

interface CreateFileBoxProps {
  setCreate: Dispatch<React.SetStateAction<boolean>>;
  history: any;
}

interface ObjectData {
  id: string;
  createdAt: string;
  name: string;
  shortPath: string;
  creatorEmail: string;
}

const App: React.FC = () => {
  const [create, setCreate] = useState(false);
  const [scan, setScan] = useState(false);

  const Home: React.FC<RouteComponentProps> = ({ history }) => (
    <React.Fragment>
      {create && <CreateFileBox setCreate={setCreate} history={history} />}
      {scan && <Scan />}
      {!create && !scan && <MainBox setCreate={setCreate} setScan={setScan} />}
    </React.Fragment>
  );

  return (
    <div className='App'>
      <header className='App-header'>
        <h1 style={{ fontWeight: 400 }}>Revision.Link</h1>
        <Router>
          <Switch>
            <Route exact path='/e/:shortPath' component={File} />
            <Route exact path='/:shortPath' component={Version} />
            <Route path='/' component={Home} />
          </Switch>
        </Router>
      </header>
    </div>
  );
};

const MainBox: React.FC<MainBoxProps> = ({ setCreate, setScan }) => {
  return (
    <React.Fragment>
      <div className='scan-code-box'>
        <h1 className='scan-code-title'>Revision.Link masters the revisions of your files in the easiest way.</h1>
        <span className='normal-button' onClick={() => setScan(true)}>
          Scan Code
        </span>
      </div>
      <span className='link-button' onClick={() => setCreate(true)}>
        Manage file version
      </span>
    </React.Fragment>
  );
};

const CreateFileBox: React.FC<CreateFileBoxProps> = ({ setCreate, history }) => {
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');

  async function handelCreate() {
    const path = `objects`;
    const postData = { email, name };
    try {
      const response = await axios.post(path, postData);
      const data: ObjectData = response.data;
      history.push(`e/${data.shortPath}`);
    } catch (err) {
      console.log(err);
    }
  }

  console.log(history);
  return (
    <React.Fragment>
      <span>your email</span>
      <input value={email} onChange={e => setEmail(e.target.value)} placeholder='your email here...'></input>
      <br />
      <span>file name</span>
      <input value={name} onChange={e => setName(e.target.value)} placeholder='the file name...'></input>
      <span style={{ marginTop: '30px' }}>
        <span className='link-button' onClick={handelCreate}>
          Create
        </span>
        <span style={{ marginRight: '60px' }}></span>
        <span className='link-button invalid-link' onClick={() => setCreate(false)}>
          Cancel
        </span>
      </span>
    </React.Fragment>
  );
};

export default App;
