import React, { useState, useEffect } from 'react';
import './App.css';
import { RouteComponentProps } from 'react-router';
import axios from 'axios';
import check from './icons/check.svg';
import cross from './icons/cross.svg';

type TParams = { shortPath: string };

interface VersionData {
  id: string;
  currentIndex: number;
  lastestIndex: number;
  createdAt: string;
  name: string;
  shortPath: string;
  object: {
    id: string;
    createdAt: string;
    name: string;
    shortPath: string;
    creatorEmail: string;
  };
}

interface TransferData {
  data: VersionData;
}

const Version: React.FC<RouteComponentProps<TParams>> = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    GetVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function GetVersion() {
    setLoading(true);
    const path = `versions/${match.params.shortPath}`;
    try {
      const response = await axios.get(path);
      const data: VersionData = response.data;
      const isUptodate = data.currentIndex === data.lastestIndex;
      setLoading(false);
      if (isUptodate) {
        setResult(
          <React.Fragment>
            <Updated />
            <Details data={data} />
          </React.Fragment>
        );
      } else {
        setResult(
          <React.Fragment>
            <Outdated />
            <Details data={data} />
          </React.Fragment>
        );
      }
    } catch (err) {
      setResult(<Error />);
    }
  }

  if (loading) return <React.Fragment>loading...</React.Fragment>;

  return <React.Fragment>{result}</React.Fragment>;
};

const Updated: React.FC = () => {
  return (
    <React.Fragment>
      <img alt='check' src={check} width='80px' />
      <span>This version is up-to-date.</span>
    </React.Fragment>
  );
};

const Outdated: React.FC = () => {
  return (
    <React.Fragment>
      <img alt='cross' src={cross} width='80px' />
      <span>This version is outdated.</span>
    </React.Fragment>
  );
};

const Error: React.FC = () => {
  return <span className='invalid-link'>This link is not valid anymore.</span>;
};

const Details: React.FC<TransferData> = ({ data }) => {
  const [show, setShow] = useState(false);

  if (show) {
    return (
      <div className='revision-details' style={{ maxHeight: '100px' }}>
        <p>
          <span>file name:</span> {data.object.name}
        </p>
        <p>
          <span>revision name:</span> {data.name}
        </p>
        <p>
          <span>created at:</span> {new Date(data.createdAt).toLocaleString()}
        </p>
        <p>
          <span>current iteration:</span> {data.currentIndex}
        </p>
        <p>
          <span>latest iteration:</span> {data.lastestIndex}
        </p>
        <p>
          <span style={{ color: '#61dafb', cursor: 'pointer' }} onClick={() => setShow(false)}>
            show less
          </span>
        </p>
      </div>
    );
  } else {
    return (
      <div className='revision-details'>
        <span style={{ color: '#61dafb', cursor: 'pointer' }} onClick={() => setShow(true)}>
          show details
        </span>
      </div>
    );
  }
};
export default Version;
