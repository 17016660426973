import React from 'react';
import QrReader from 'react-qr-reader';
import './App.css';

const File: React.FC = () => {
  function handleScan(data: any) {
    console.log(data);
    if (data) window.open(data, '_self');
  }
  function handleError(err: any) {
    console.error(err);
  }
  return (
    <React.Fragment>
      <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} showViewFinder={false} />
    </React.Fragment>
  );
};

export default File;
